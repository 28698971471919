@import 'scss/variables';

.underline {
  text-decoration: underline;
  color: $primary;
}

.primary {
  color: $primary;
}

.handjesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  margin-top: 2rem;
  margin-bottom: 4rem;

  img {
    max-width: 5rem;
    position: relative;
    opacity: 0;
    margin: 1rem 1rem;

    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation-name: scrollInFromBottom;
    animation-fill-mode: forwards;
  }
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  .big,
  h1 {
    font-size: 2.4rem;
    font-weight: 500;
  }

  p {
    margin-bottom: 1.6rem;
  }
}

@keyframes scrollInFromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
