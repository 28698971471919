@import 'scss/variables';

$breakpoint: 450px;

.container {
  width: 100%;
  max-width: 144rem;
  background-color: #fff;
  min-height: 4rem;
  padding: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 auto;

  @media (max-width: $breakpoint) {
    flex-direction: column;
    min-height: auto;
  }

  .logo {
    display: block; /* only block level elements can have width / height */
    width: 7.15rem; /* stretch link to desired width */
    height: 10.25rem; /* stretch link to desired height */
    white-space: nowrap; /* single line of text */
    text-indent: 100%; /* push text outside */
    overflow: hidden; /* hide text outside */
    background-image: url(../assets/logo.svg); /* set background image */
    background-size: 100% 100%; /* stretch background image */
  }

  .colLeft {
    width: 12rem;

    @media (max-width: $breakpoint) {
      width: auto;
    }
  }

  .colRight {
    flex-grow: 1;

    display: flex;
    flex-direction: row;

    @media (max-width: $breakpoint) {
      // flex-direction: column;
      width: 100%;
      margin-top: 1rem;
    }

    justify-content: flex-end;

    > * {
      margin-right: 3.2rem;
      max-width: 100%;

      @media (max-width: $breakpoint) {
        margin: 0.8rem 0;
        text-align: center;
        width: 100%;
      }
    }
  }
}
