@import 'scss/variables';

.verification {
  position: relative;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 0.5rem;
  padding: 0.3rem 0.6rem;
  border: 0.1rem solid;
  letter-spacing: 0.1rem;

  .tooltip {
    display: none;
    position: absolute;
    left: 0;
    top: 2.2rem;

    width: 24rem;
    padding: 1rem 1.5rem;

    text-transform: none;
    background-color: #fff;
    color: #555;
    font-size: 1.2rem;
    font-weight: 500;

    border-radius: 5px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25);
  }

  &:hover .tooltip {
    display: block;
  }

  &.certificate_ok {
    color: $black;
    background-color: $secondary;
    border: none;
  }

  &.valid {
    display: none;
  }

  &.invalid {
    color: $error;
  }
}
