@import 'scss/variables';

.maincontainer {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
}

.container {
  position: absolute;
  display: none;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 76rem;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 800;
  width: 100%;

  padding: 2rem;
  border-radius: 0.5rem;

  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.4);

  hr {
    border-top: 1px solid $primary;
  }

  .topRow {
    width: 100%;

    h2 {
      width: 90%;
      display: inline-block;
    }

    .closeIcon {
      max-width: 10%;
      display: inline-block;
      padding: 2rem;
      color: $primary;
      font-weight: bold;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.visible {
    display: block;
  }

  .label {
    color: $primary;
    width: 15rem;
    display: inline-block;
  }

  .quote {
    font-style: italic;
  }

  label {
    display: inline-block;
  }

  .horiz {
    content: ' ';
    display: inline-block;
    width: 5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  h3 {
    margin-top: 3.2rem;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .buttons {
    margin-bottom: 1.6rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      display: block;
      padding: 0.8rem 2.4rem;
      margin: 1.5rem 1rem;

      font-size: 1.6rem;

      border: none;
      border-radius: 0.4rem;

      height: 4rem;
      width: 100%;
      transition: 0.15s ease-out;

      &.blue {
        background-color: $primary;
        color: $white;

        &:hover {
          background-color: $primary-light;
          cursor: pointer;
        }
      }

      &.outline {
        border: 1px solid $primary;
        background-color: $white;
        color: $primary;

        &:hover {
          background-color: $primary-light;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }

  .replies {
    h4 {
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 0.8rem;
      margin-top: 3.2rem;
    }
    p {
      line-height: 100%;
    }

    .extraInfo {
      margin-bottom: 1.6rem;
      font-size: 1.2rem;

      li {
        margin-left: 0.8rem;
        color: #555;

        &:before {
          content: '- ';
        }
      }
    }
  }
}
