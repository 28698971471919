@import 'scss/variables';

.filter{
    margin: 1rem auto;
    max-width: 96rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
    label{
        margin: 0;
    }
    input{
        z-index: 1;
    }


}