@import 'scss/variables';

.card {
  cursor: pointer;

  padding: 1rem 1.6rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(39, 40, 51, 0.2);
  background: #ffffff;
  border-radius: 0.5rem;

  max-width: 96rem;

  margin: 1rem auto;

  h2 {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    color: #6b6c7e;
    // text-transform: lowercase;
  }

  div {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: $secondary;

    a {
      color: $primary;
      text-decoration: underline;
      font-weight: 600;

      &:hover {
        text-decoration: none;
        color: $secondary;
      }
    }
  }
}
