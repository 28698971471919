@import 'scss/variables';

.container {
  background-color: $disabled;
  color: $primary;
  padding: 4rem 2rem;

  h4 {
    font-size: 2.4rem;
    font-weight: 600;

    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
  }

  label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  a {
    color: $primary-light;

    &:hover {
      color: #fff;
    }
  }
}
