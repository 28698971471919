@import 'scss/variables';

.container {
  // width: auto;
  display: block;
  padding: 0.8rem 2.4rem;
  margin: 3.2rem auto;

  font-size: 1.6rem;

  border: none;
  border-radius: 0.4rem;

  height: 4rem;
  width: 100%;
  max-width: 96rem;
  transition: 0.15s ease-out;

  &.blue {
    background-color: $primary;
    color: #fff;
  }

  &.white {
    background-color: #fff;
    color: #000;
  }

  &:hover {
    background-color: $primary-light;
    cursor: pointer;
  }
}
