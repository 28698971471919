@import 'scss/variables';

$breakpoint: 450px;

.content {
  h1 {
    text-align: center;
  }
}

.intro {
  display: flex;
  flex-direction: row;
  max-width: 96rem;

  & > * {
    margin-bottom: 3rem;
  }

  img {
    height: 15rem;
  }

  a {
    display: block;
  }

  @media (max-width: $breakpoint) {
    flex-direction: column;
  }
}

.links {
  margin-top: 8rem;

  p {
    text-align: center;
    width: 100%;
    margin-bottom: 1.6rem;
  }
}

.filter_wrapper{
  margin: 1rem auto;
  max-width: 96rem;
  display: flex;
  .btn_filter{
    border: none;
    display: flex;
    align-items: center;
    color: $primary;
    font-weight: bold;
    border: 1px solid $primary;
    background-color: white;
    &:hover{
      cursor: pointer;
    }
    .icon_filter{
      background-image: url('../assets/icons/filter-icon.svg');
      background-size: 3rem 3rem;
      width: 3rem;
      height: 3rem;
      display: block;
      margin-right: 1rem;
    }
  }
  .btn_clear_filters{
    border: none;
    background-color: white;
    cursor: pointer;
    .icon_close{
      font-weight: bold;
      font-style: normal;
    }
  }
}