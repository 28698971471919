@import './scss/reset';
@import './scss/variables';

/**********************************************/
/*** General attributes and app look & feel ***/
/**********************************************/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $text;
}

nav a {
  font-size: 1.4rem;
  font-weight: 600;
  color: #000;
  text-decoration: underline;

  &:hover {
    color: $black;
    text-decoration: none;
  }
}

section {
  margin: 1rem auto;
}

p {
  line-height: 2.5rem;
}

a {
  color: $primary;
  text-decoration: none;

  transition: 0.15s ease-out;

  &.active {
    color: $black;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
    color: darken($color: $primary, $amount: 20%);
  }
}

label {
  display: block;
  margin: 1.5rem auto 0;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 3.2rem;
}

.form {
  max-width: 96rem;
  margin: 5rem auto 5rem auto;
}

.intro {
  max-width: 96rem;
  margin: 1rem auto;
}

input[type='submit'] {
  padding: 0.8rem 2.4rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 0.4rem;

  height: 4rem;
  width: 100%;
  transition: 0.15s ease-out;

  background-color: $primary;
  color: #fff;

  &:hover {
    background-color: $primary-light;
    cursor: pointer;
  }
}

input[type='text'],
input[type='password'],
select {
  width: 100%;
  display: inline-block;
  border-radius: 0.4rem;
  box-sizing: border-box;
  border: 0.1rem solid #e7e7ed;
  border-top: 1px solid #ddd;
  background-color: $white;
  font-size: 17px;
  padding: 6px 12px;
  font-size: 14px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
input[type='text'].email {
  width: 60%;
}
input[type='password'] {
  max-width: 30rem;
}
input[type='submit'] {
  width: auto;
}

textarea {
  width: 100%;
  padding: 1rem 1.6rem;
  box-sizing: border-box;
  background: #f1f2f5;
  border: 0.1rem solid #e7e7ed;
  border-radius: 0.4rem;
  resize: vertical;
  border: 0.1rem solid #e7e7ed;
  border-top: 1px solid #ddd;
  background-color: $white;
  font-size: 17px;
  padding: 6px 12px;
  font-size: 14px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}

/************************************/
/*** More specific css classnames ***/
/************************************/

.icon {
  display: inline-block;
  height: 16px;
  width: 16px;

  vertical-align: middle;
  margin-right: 1rem;
  &.plus{
    background-image: url('../assets/icons/plus-icon.svg');
  }
  &.trash{
    background-image: url('../assets/icons/trash-icon.svg');
  }
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.icon-trash{

}

.center {
  text-align: center;
}

.progress {
  text-align: center;
  font-size: 1rem;
  margin: 3rem 0;

  li {
    position: relative;
    width: 8rem;
    min-width: 8rem;
    display: inline-block;

    span {
      color: $primary;
      font-weight: bold;
      margin: 0 auto;
      background-color: $disabled;
      font-size: 1.4rem;
      display: block;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      line-height: 32px;

      &.active {
        background-color: $secondary;
        color: #fff;
      }
    }

    p {
      color: #a7a9bc;
      text-transform: uppercase;
    }
  }

  li::before {
    content: '';
    position: absolute;
    top: 1.5rem;
    left: -3rem;
    width: 7rem;
    height: 0.4em;
    background: $black;
    z-index: -1;
  }

  li:first-child::before {
    display: none;
  }

  .active {
    background: $secondary;
  }

  .active ~ li {
    background: lightblue;
  }

  .active ~ li::before {
    background: lightblue;
  }
}

.remark {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #6b6c7e;
}
