@import 'scss/variables';

.nomargin {
  margin: 0;
}

table {
  //table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  thead tr {
    font-weight: 600;
    cursor: pointer;

    & td:hover {
      text-decoration: underline;
      color: $primary;
    }

    & td.sortable::after {
      content: '▼';
      margin: 0 0.5rem;
    }

    & td.sortable.down::after {
      content: '▲';
      margin: 0 0.5rem;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e7e7ed;
      height: 6rem;

      &:hover {
        cursor: pointer;
        background-color: $disabled;
        color: $primary;
      }
    }

    p {
      padding: 2rem;
    }
  }

  td {
    padding: 0.5rem 2rem;
    width: auto;
  }
}

.tabbar {
  margin-bottom: 2rem;
  background-color: $primary;
  color: $white;

  p {
    display: inline-block;
    padding: 2rem 4rem 1rem 4rem;

    &:hover {
      cursor: pointer;
    }

    &.active {
      border-bottom: 6px solid $secondary;
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  padding: 0 2rem;

  button {
    width: 20rem;
  }
}

.exporttab {
  padding: 2rem;
}
