@import 'scss/variables';

.error {
  color: $error;
  display: flex;
  justify-content: center;
}

.almostThere {
  font-size: 2.4rem;
  font-weight: 600;
  margin: 2rem 0 4.8rem 0;
}
