.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70%;

  font-size: 1.8rem;
  text-align: center;

  & > * {
    margin-bottom: 2rem;
  }

  .error {
    color: red;
    display: flex;
    justify-content: center;
  }
}
