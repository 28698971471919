@import 'scss/variables';

.details {
  max-width: 96rem;
  span {
    margin-left: 2rem;
  }
}

.info {
  color: $primary-light;
}

.error {
  color: $error;
  display: flex;
  justify-content: center;
}

.nopadding {
  padding: 0;
}

.padding {
  padding: 2rem;
}
