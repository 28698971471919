@import 'scss/variables';

.breadcrumb {
  max-width: 96rem;
  margin: 0 auto;

  p {
    font-size: 1.2rem;
  }
}
