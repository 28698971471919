@import 'scss/variables';

.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;

  .title,
  .title a {
    // background-color: #30313f;
    width: 100%;
    height: 6rem;

    text-align: center;
    line-height: 6rem;
    color: $black;
    font-weight: bold;
  }
}

.content {
  flex-grow: 1;
}
