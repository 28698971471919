@import 'scss/variables';

.container {
  text-align: center;
  min-height: 15rem;
  width: 100%;
  background-color: $primary;
  color: #fff;
  padding: 2rem 4rem;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
}
