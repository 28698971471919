$text: #333;

$primary: #1E64C8;
$primary-light: #4d8cdf;
$primary-lighter: #80ace6;

$black: #000;
$white: #fff;

$secondary: #FFD200;
$secondary-light: #ffea8e;

$disabled: #e9f0fa;

$colortest: rgb(5, 119, 35);

$error: rgb(228, 38, 25);
