.container {
  min-height: 100vh;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  padding: 2rem;
  padding-top: 0;

  h1 {
    font-weight: 600;
    margin-bottom: 1rem;
  }
}
